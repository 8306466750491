import React from "react"
import Header from "./header"
import "../styles/layout.css"

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scrolled: false,
    }
  }

  render() {
    const {
      children,
      onHideNav,
      onShowNav,
      showNav,
      siteTitle,
      navMenuItems,
      location,
      textWhite = false,
    } = this.props
    const { scrolled } = this.state
    return (
      <>
        <Header
          navMenuItems={navMenuItems}
          siteTitle={siteTitle}
          onHideNav={onHideNav}
          onShowNav={onShowNav}
          showNav={showNav}
          scrolled={scrolled}
          textWhite={textWhite}
          location={location}
        />
        <>{children}</>
        {/* <Footer siteTitle={siteTitle} /> */}
      </>
    )
  }
}

export default Layout
