import clientConfig from "../../client-config";
import imageUrlBuilder from "@sanity/image-url";
import { getFluidGatsbyImage } from "gatsby-source-sanity";

const builder = imageUrlBuilder(clientConfig.sanity);

export function imageUrlFor(source) {
  return builder.image(source);
}
export const getFluidProps = (imageId: string, options = { maxWidth: 675 }) => {
  const fluidProps = getFluidGatsbyImage(imageId, options, clientConfig.sanity);

  return fluidProps;
};
