import { Link } from "gatsby"
import React from "react"
import CTALink from "./CTALink"
import { cn } from "../lib/helpers"
import styles from "./header.module.css"

const Header = ({
  location,
  showNav,
  siteTitle,
  scrolled,
  navMenuItems = [],
  textWhite = false,
}) => {
  const pathname = location?.pathname
  let headerClass = "w-full z-30 top-0 relative lg:flex lg:flex-wrap"
  headerClass += scrolled ? " bg-white shadow" : ""

  let navActionClass =
    "mx-auto lg:mx-0 text-sm hover:underline font-bold rounded-full lg:mt-0 py-4 px-8 shadow opacity-75"
  navActionClass += !textWhite || !scrolled ? " text-gray-800" : ""
  navActionClass += textWhite || scrolled ? " gradient" : ""

  let navContentClass =
    "w-full flex-grow text-xs lg:flex font-medium lg:items-center lg:w-auto lg:block my-2 lg:mt-0 lg:pt-6 z-20"
  navContentClass += !textWhite || !scrolled ? " lg:bg-transparent" : ""
  navContentClass += textWhite || scrolled ? " " : ""

  let titleClass = "toggleColour text-xs mr-3 uppercase no-underline  font-semibold "
  titleClass += !textWhite || scrolled ? " text-gray-800" : ""
  titleClass += textWhite || !scrolled ? " " : ""

  return (
    <nav id="header" className={headerClass}>
      <div className="mx-auto flex flex-wrap items-centermt-0 px-4 lg:px-6 pt-6">
        <div className="flex">
          <Link id="siteTitle" className={cn(titleClass, styles.titleClass)} to="/">
            {siteTitle}
          </Link>
        </div>
      </div>
      {showNav && navMenuItems && (
        <div className={navContentClass} id="nav-content">
          <ul className="list-reset py-3 md:py-0 flex flex-1 items-center pl-4 lg:pl-6 overflow-x-auto ">
            {navMenuItems.map((i) => (
              <li
                key={i.title}
                className={cn(
                  styles.navItem,
                  "mr-1 md:mr-6 uppercase",
                  pathname === `/${i.landingPageRoute?.slug?.current}` ? "active" : ""
                )}
              >
                <CTALink {...i} buttonActionClass={navActionClass} />
              </li>
            ))}
          </ul>
        </div>
      )}
    </nav>
  )
}

export default Header
